<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="giftsForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="giftsForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="giftsForm.description_en"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="giftsForm.description_ar"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Active"
            label-for="Active"
          >
            <b-form-checkbox
              id="Active"
              v-model="giftsForm.status"
              unchecked-value="0"
              value="1"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">

          <b-media>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="giftsForm.image"
                  height="100"
                  width="100"
                />
              </b-link>
            <!--/ image -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload Images
              </b-button>
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png"
                :hidden="true"
                plain

                @change="changeProfile($event)"
              />
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="
              invalid ||
                Object.values(errors).length > 0
            "
            @click="addGift()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addGift()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      countries: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const image = ref('')

    const giftsForm = ref({
      image: null,
      status: 0,
    })
    return {
      getValidationState,
      giftsForm,
      image,
    }
  },
  mounted() {
    this.showProduct()
    this.getCountries()
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        const standard = 1.62 / 0.98
        const belowStandard = 1.62 / 1.05
        const userRatio = img.width / img.height
        if (userRatio <= standard && userRatio >= belowStandard) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.giftsForm.image = er.target.result
            }
            // eslint-disable-next-line prefer-destructuring
            this.image = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image Aspect Ratio is Not Valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
    },

    async searchCountries(searchQuery) {
      await axios.get(`admin/countries?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async getCountries() {
      await axios.get('admin/countries').then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    showProduct() {
      if (this.$route.params.id) {
        axios.get(`admin/gifts/${this.$route.params.id}`).then(res => {
          this.giftsForm = res.data.data.product
        })
      } else {
        return false
      }
      return true
    },
    addGift() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.giftsForm) {
          if (key !== 'image') {
            formData.append(key, this.giftsForm[key])
          }
        }
        if (this.image) {
          formData.append('image', this.image)
        }
        axios.post(`admin/gifts/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'gifts' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.giftsForm) {
          if (key !== 'image') {
            formData.append(key, this.giftsForm[key])
          }
        }

        if (this.image) {
          formData.append('image', this.image)
        }
        axios.post('admin/gifts', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'gifts' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
